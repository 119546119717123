import dayjs from 'dayjs'
import { combineEpics, Epic, ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import {
  getOrderToastContents,
  getOrderToastType
} from '../../components/Toasts/helpers.tsx'
import { OrdersUpdatedAction } from '../order/actions'
import { getSecurityStaticDataById } from '../securities/selectors.ts'
import { addToast } from './actions.ts'

const orderToastEpic: Epic = (action$, state$) =>
  action$.pipe(
    ofType<OrdersUpdatedAction>('order.ordersUpdated'),
    map((action) => {
      const order = action.payload
      const staticData = getSecurityStaticDataById(state$.value)(
        order.securityId
      )
      return addToast({
        message: getOrderToastContents(order, staticData?.boardLabel ?? ''),
        options: {
          toastId: `${order.transactionId}-${dayjs().toString()}`,
          type: getOrderToastType(order.status),
          position: 'bottom-right',
          autoClose: false
        }
      })
    })
  )

export default combineEpics(orderToastEpic)

import { CustomCellRendererProps } from '@ag-grid-community/react'
import React, { useCallback } from 'react'
import CancelCheck from '../../../components/IconCheckbox/CancelCheck'
import { getSecurityCancelCheckedStatus } from '../../../store/listTrading/selectors'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { useAppSelector } from '../../../store/types'
import { WatchList } from '../../../store/watchList/types'
import { TradingListContext } from '../Grid'
import { useGetListTradingSecurity } from '../hooks/useGetListTradingSecurity'
import { useListTradingAggressOrders } from '../hooks/useListTradingAggressOrders'

interface Props {
  security: ListTradingSecurity
  watchlistId: WatchList['id']
}

const SelectSecurityOrdersToCancelCheckbox = ({
  security,
  watchlistId
}: Props) => {
  const checkedStatusSelector = useAppSelector(getSecurityCancelCheckedStatus)
  const type = security.isBid ? 'buy' : 'sell'
  const status = checkedStatusSelector(security.id, watchlistId, type)
  const { selectOrDeselectAllSecurityOrders } = useListTradingAggressOrders(
    security.id,
    security.isBid,
    watchlistId
  )
  const onChange = useCallback(() => {
    selectOrDeselectAllSecurityOrders(status !== 'all')
  }, [status])

  if (status === 'hidden') {
    return <></>
  }

  return (
    <CancelCheck
      checked={status === 'all'}
      indeterminate={status === 'some'}
      onChange={onChange}
      testId={`cancel-${security.id}-aggress-orders-check`}
      title={`Select all pending orders for "${security.securityDescription}" to cancel`}
    />
  )
}

const SelectSecurityOrdersToCancelCell = ({
  data,
  context: { watchlistId }
}: CustomCellRendererProps<SecurityStaticData, never, TradingListContext>) => {
  const security = useGetListTradingSecurity(data?.id)
  if (security) {
    return (
      <SelectSecurityOrdersToCancelCheckbox
        security={security}
        watchlistId={watchlistId}
      />
    )
  }
}

export default SelectSecurityOrdersToCancelCell

import React, { PropsWithChildren } from 'react'
import styles from './Badge.module.scss'

export interface Props {
  color: 'red' | 'yellow' | 'green'
}

const Badge = ({ color, children }: PropsWithChildren<Props>) => {
  return <span className={styles[`badge-${color}`]}>{children}</span>
}

export default Badge

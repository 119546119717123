import React, { FC } from 'react'
import { SubAccountInfo } from '../../store/userEditorData/types'
import Checkbox from '../Checkbox'

interface Props {
  subAccounts: SubAccountInfo[]
  enabledSubAccounts: SubAccountInfo[]
  setSubAccounts: (info: SubAccountInfo) => void
  defaultAccount: SubAccountInfo | undefined
  setDefaultAccount: (info: SubAccountInfo) => void
  automaticallyEnabledNewAccounts: boolean
  setAutomaticallyEnabledNewAccounts: (a: boolean) => void
}

const SubAccountEditor: FC<Props> = (props: Props) => {
  const subAccounts = props.subAccounts
  const enabledSubAccounts = props.enabledSubAccounts
  const setSubAccounts = props.setSubAccounts
  const defaultAccount = props.defaultAccount
  const setDefaultAccount = props.setDefaultAccount
  // const automaticallyEnabledNewAccounts = props.automaticallyEnabledNewAccounts
  // const setAutomaticallyEnabledNewAccounts = props.setAutomaticallyEnabledNewAccounts
  return (
    <div>
      <table>
        <thead>
          <tr>
            <td>Sub-Account</td>
            <td>Enabled</td>
            <td>Default</td>
          </tr>
          {subAccounts.map((sa) => {
            return (
              <tr key={sa.custId}>
                <td>
                  {sa.custName} : {sa.description} {sa.custId}
                </td>
                <td>
                  <Checkbox
                    locator={''}
                    checked={
                      enabledSubAccounts.findIndex(
                        (s) => s.custId === sa.custId
                      ) > -1
                    }
                    onChange={() => setSubAccounts(sa)}
                    disabled={false}
                  ></Checkbox>
                </td>
                <td>
                  <Checkbox
                    locator={''}
                    checked={defaultAccount?.custId === sa.custId}
                    onChange={() => setDefaultAccount(sa)}
                    disabled={false}
                  ></Checkbox>
                </td>
              </tr>
            )
          })}
        </thead>
      </table>
    </div>
  )
}

export default SubAccountEditor

import {
  faMagnifyingGlass,
  faTimesCircle
} from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React, { forwardRef } from 'react'
import styles from './SearchInput.module.scss'

export type Props = React.ClassAttributes<HTMLInputElement> &
  React.InputHTMLAttributes<HTMLInputElement> & {
    onClear?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  }

/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
const SearchInput = forwardRef<HTMLInputElement, Props>(
  ({ onClear, className, value, ...props }, ref) => {
    return (
      <div className={cx(className, styles.wrapper)}>
        <FontAwesomeIcon className={styles.icon} icon={faMagnifyingGlass} />
        <input
          {...props}
          value={value}
          type="text"
          className={styles.search}
          ref={ref}
        />
        {value !== '' && (
          <button
            type="button"
            data-testid="clear-button"
            className={styles.clearButton}
            onClick={onClear}
          >
            <FontAwesomeIcon className="pointer" icon={faTimesCircle} />
          </button>
        )}
      </div>
    )
  }
)

export default SearchInput

import React from 'react'
import { useSelector } from 'react-redux'
import { useOpenFin } from '../../../app/openFinContext'
import Popout from '../../../containers/Popouts/Popout'
import { foregroundAggressorWindowAtIndex } from '../../../store/windows/actions'
import {
  getActiveAggressorWindow,
  getAggressorWindows
} from '../../../store/windows/selectors'
import Wrapper from '../../Wrapper/Wrapper'
import AggressorModal from '../AggressorModal/AggressorModal'
import { useActiveWindow } from '../helpers'

const AggressorModalStack = () => {
  const { fin } = useOpenFin()
  const aggressorWindows = useSelector(getAggressorWindows)
  const activeModalIndex = useSelector(getActiveAggressorWindow)
  const { handleMinimize } = useActiveWindow(
    aggressorWindows.length,
    activeModalIndex,
    foregroundAggressorWindowAtIndex
  )

  return aggressorWindows.length > 0 ? (
    <Wrapper>
      {aggressorWindows.map((windowInfo, i) =>
        fin ? (
          <Popout
            key={windowInfo.transactionId}
            title={`Aggressor/${windowInfo.transactionId}`}
            windowInfo={windowInfo}
          >
            <AggressorModal
              windowInfo={windowInfo}
              isActiveWindow={activeModalIndex === i}
              handleMinimize={handleMinimize}
              index={i}
            />
          </Popout>
        ) : (
          <AggressorModal
            key={windowInfo.initialOrderId + windowInfo.transactionId}
            windowInfo={windowInfo}
            isActiveWindow={activeModalIndex === i}
            handleMinimize={handleMinimize}
            index={i}
          />
        )
      )}
    </Wrapper>
  ) : null
}
export default AggressorModalStack

import React, { useCallback } from 'react'
import {
  cancelListTradingOrders,
  selectListTradingSecurities,
  tradeSelectedSecurities
} from '../../store/listTrading/actions'
import {
  getCountOfOrdersToCancel,
  getSelectedRowCount,
  getSelectedTradeCount
} from '../../store/listTrading/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import styles from './styles.module.scss'

interface Props {
  watchlistId: number
}

const CancelSubmitTrades = ({ watchlistId }: Props) => {
  const selectedRowCount = useAppSelector(getSelectedRowCount)
  const selectedTradeCount = useAppSelector(getSelectedTradeCount)

  const s = selectedTradeCount > 1 ? 's' : ''
  const label = `Submit ${selectedTradeCount} Trade Attempt${s}`

  const selectedForCancelCount = useAppSelector(getCountOfOrdersToCancel)
  const cancelS = selectedForCancelCount > 1 ? 's' : ''
  const cancelLabel = `Cancel ${selectedForCancelCount} Aggress Attempt${cancelS}`

  const dispatch = useAppDispatch()
  const submitTrades = useCallback(() => {
    if (watchlistId) {
      dispatch(tradeSelectedSecurities(watchlistId))
      dispatch(selectListTradingSecurities([]))
    }
  }, [watchlistId])
  const cancelTrades = useCallback(() => {
    if (watchlistId) {
      dispatch(cancelListTradingOrders(watchlistId))
    }
  }, [watchlistId])
  return (
    <div className={styles.controls}>
      {selectedForCancelCount > 0 && (
        <button
          id="cancel-selected-trades"
          data-testid="cancel-selected-trades"
          onClick={cancelTrades}
        >
          {cancelLabel}
        </button>
      )}
      {selectedRowCount > 0 && (
        <button
          id="submit-selected-trades"
          data-testid="submit-selected-trades"
          onClick={submitTrades}
        >
          {label}
        </button>
      )}
    </div>
  )
}

export default CancelSubmitTrades

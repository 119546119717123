import React, { PropsWithChildren, useCallback, useEffect, useRef } from 'react'
import { useDropDownMenu } from '../../helpers/hooks'
import styles from './dropDownMenu.module.scss'

interface Props {
  buttonElement: () => (showMenu: () => void) => JSX.Element
  actionAfterClose?: () => void
  closeDropdown: boolean
}

const DropDownMenu = ({
  buttonElement,
  children,
  actionAfterClose,
  closeDropdown
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null)
  const { displayMenu, setDisplayMenu } = useDropDownMenu(ref)

  const showDropdownMenu = useCallback(() => {
    setDisplayMenu(true)
    if (!displayMenu && actionAfterClose) {
      actionAfterClose()
    }
  }, [displayMenu])

  useEffect(() => {
    if (closeDropdown) {
      setDisplayMenu(false)
    }
  }, [closeDropdown])

  return (
    <div ref={ref} className={styles.dropdownMenu}>
      {buttonElement()(showDropdownMenu)}
      {displayMenu && children}
    </div>
  )
}

export default DropDownMenu

import React, { ChangeEvent, PropsWithChildren, useCallback } from 'react'
import { noop } from 'rxjs'

import styles from './index.module.scss'

export interface Props {
  onChange?: (e: React.ChangeEvent) => void
  locator: string
  checked?: boolean
  disabled?: boolean
  name: string
  value: string
}

const Radio = ({
  children,
  locator,
  /* eslint-disable no-empty-function */
  onChange = noop,
  name,
  value,
  disabled = false,
  checked = false
}: PropsWithChildren<Props>) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!disabled) {
        onChange(event)
      }
    },
    [disabled, onChange]
  )

  return (
    <div className={styles.radioWrapper}>
      <input
        type="radio"
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        checked={checked}
      />
      <label data-testid={locator} className={styles.text}>
        {children}
      </label>
    </div>
  )
}

export default Radio

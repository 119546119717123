import {
  CellEditingStoppedEvent,
  CellKeyDownEvent,
  ProcessDataFromClipboardParams
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'

import { useAppSelector } from '../../../../store/types'
import { getCurrentTheme } from '../../../../store/userPreferences/selectors'
import { rowSelection } from '../../helpers'
import styles from './AdvancedFilterEditor.module.scss'

type Props = {
  symbolsAndCusips: string[]
  setSymbolsAndCusips: (symbolsAndCusips: string[]) => void
}

const rawSymbolsAndCusipsToRowData = (symbol: string) => ({ symbol })

type RowData = ReturnType<typeof rawSymbolsAndCusipsToRowData>

const minRowcount = 12

const SymbolAndCusipEditor = ({
  symbolsAndCusips,
  setSymbolsAndCusips
}: Props) => {
  const [localSymbolsAndCusips, setLocalSymbolsAndCusips] =
    useState(symbolsAndCusips)

  const rowData = useMemo(() => {
    const populatedRows = localSymbolsAndCusips.filter((value) => value)
    const extraRowsNeeded = Math.max(1, minRowcount - populatedRows.length)
    const extraRows = ','.repeat(extraRowsNeeded).split(',')
    return [...populatedRows, ...extraRows].map(rawSymbolsAndCusipsToRowData)
  }, [localSymbolsAndCusips])
  const gridRef = useRef<AgGridReact>(null)

  const containerRef = useRef<HTMLDivElement>(null)

  const theme = useAppSelector(getCurrentTheme)
  const classes = `${theme} ${styles['symbol-cusip-grid']}`

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: 'Bond Ticker, Cusip, or Isin',
        field: 'symbol' as const,
        editable: true,
        cellEditor: 'agTextCellEditor',
        flex: 100
      }
    ]
  }, [])

  const onCellEditingStopped = useCallback(
    ({ api, valueChanged }: CellEditingStoppedEvent) => {
      if (valueChanged) {
        const symbols: string[] = []
        api.forEachNode(({ data }) => {
          if (data.symbol) {
            const symbol = data.symbol.trim()
            if (!symbol.includes(' ')) {
              symbols.push(symbol)
            } else {
              symbols.push(...symbol.split(' '))
            }
          }
        })
        setLocalSymbolsAndCusips(symbols)
      }
    },
    [setSymbolsAndCusips]
  )

  const processPastedSymbols = useCallback(
    ({ data, api }: ProcessDataFromClipboardParams<RowData>) => {
      const pasted = data.map((value) => value[0]).filter((item) => item)
      const pasteRow = Math.min(
        api?.getFocusedCell()?.rowIndex ?? 0,
        localSymbolsAndCusips.length
      )
      const prev = localSymbolsAndCusips.slice(0, pasteRow)
      const symbols = [...prev, ...pasted]
      setLocalSymbolsAndCusips(symbols)
      return data
    },
    [localSymbolsAndCusips]
  )

  const onCellkeyDown = useCallback(
    ({ event, api }: CellKeyDownEvent<RowData>) => {
      const keyboardEvent = event as KeyboardEvent
      if (keyboardEvent?.key === 'Delete') {
        // data for the cell is already null here, so we have to do this the hard way
        const updatedRows: string[] = []
        api.forEachNode(({ data }) => {
          if (data?.symbol) {
            updatedRows.push(data.symbol)
          }
        })
        setLocalSymbolsAndCusips(updatedRows)
      }
    },
    []
  )

  useEffect(() => {
    setSymbolsAndCusips(localSymbolsAndCusips.filter((item) => item))
  }, [localSymbolsAndCusips])

  useClickAway(containerRef, () => {
    gridRef.current?.api.stopEditing()
  })

  return (
    <div className={classes}>
      <AgGridReact<RowData>
        ref={gridRef}
        columnDefs={columnDefs}
        columnMenu="legacy"
        rowData={rowData}
        onCellEditingStopped={onCellEditingStopped}
        onCellKeyDown={onCellkeyDown}
        processDataFromClipboard={processPastedSymbols}
        suppressClipboardApi={true}
        stopEditingWhenCellsLoseFocus={true}
        enterNavigatesVertically={true}
        enterNavigatesVerticallyAfterEdit={true}
        rowSelection={rowSelection}
      />
    </div>
  )
}

export default React.memo(SymbolAndCusipEditor)

import dayjs from 'dayjs'
import React from 'react'
import { Order } from '../../store/order/types'
import { formatTradingNowDetails } from '../Activity/AlertDescription/helpers'
import styles from './Toasts.module.scss'

export const getOrderToastContents = (order: Order, boardLabel: string) => {
  const direction = order.type === 'buy' ? 'Bought' : 'Sold'
  const amount = formatTradingNowDetails(
    order.size,
    order.displayPrice,
    order.spread
  )

  const getToastBody = () => {
    if (order.status === 'accepted') {
      return (
        <p>
          You {direction} {amount}
        </p>
      )
    }
    if (order.status === 'cancelled') {
      return <p>Your attempt has been cancelled</p>
    }
    if (order.status === 'rejected') {
      return <p>Your order has been rejected</p>
    }
  }
  return (
    <>
      <div className={styles.toastHeader}>
        {boardLabel}
        <div>{dayjs(order.tradeTime).format('h:mm:ss A')}</div>
      </div>
      <div className={styles.toastBody}>{getToastBody()}</div>
    </>
  )
}

const orderToastTypeMap: Record<string, 'success' | 'warning' | 'error'> = {
  accepted: 'success',
  cancelled: 'warning',
  rejected: 'error'
}

export const getOrderToastType = (status: string) => {
  return orderToastTypeMap[status] ?? 'info'
}

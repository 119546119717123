import { CustomHeaderProps } from '@ag-grid-community/react'
import React, { useCallback, useMemo } from 'react'
import CancelCheck from '../../../components/IconCheckbox/CancelCheck'
import {
  deselectSecurityOrdersToCancel,
  selectSecurityOrdersToCancel
} from '../../../store/listTrading/actions'
import {
  getHeaderCancelCheckedStatus,
  getListTradingSecurities
} from '../../../store/listTrading/selectors'
import { getListTradeOrders } from '../../../store/order/selectors'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import { TradingListContext } from '../Grid'
import { isPending } from '../helpers'

const SelectOrdersToCancelHeaderCell = ({
  context: { watchlistId }
}: CustomHeaderProps<SecurityStaticData, TradingListContext>) => {
  const dispatch = useAppDispatch()
  const securities = useAppSelector(getListTradingSecurities)
  const getAggressOrders = useAppSelector(getListTradeOrders)
  const status = useAppSelector(getHeaderCancelCheckedStatus)(watchlistId)

  const enabled = useMemo(() => {
    const orders = securities.flatMap((security) => {
      return getAggressOrders(
        security.id,
        security.isBid ? 'buy' : 'sell',
        watchlistId
      ).filter(isPending)
    })
    return orders.length > 0
  }, [getAggressOrders, securities, watchlistId])

  const onChange = useCallback(
    (isSelected: boolean) => {
      securities.forEach((security) => {
        if (isSelected) {
          const type = security.isBid ? 'buy' : 'sell'
          const orders = getAggressOrders(
            security.id,
            type,
            watchlistId
          ).filter(isPending)
          const orderIds = orders.map((order) => order.id)
          dispatch(selectSecurityOrdersToCancel(security.id, orderIds))
        } else {
          dispatch(deselectSecurityOrdersToCancel(security.id))
        }
      })
    },
    [securities, dispatch, getAggressOrders, watchlistId]
  )

  return (
    <CancelCheck
      checked={status === 'all'}
      disabled={!enabled}
      indeterminate={status === 'some'}
      onChange={() => {
        onChange(status !== 'all')
      }}
      testId="cancel-all-selected-listTrading-orders"
    />
  )
}

export default SelectOrdersToCancelHeaderCell

import React, { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router'
import Loading from '../../components/Loading/Loading'
import { setListTradingWatchlistId } from '../../store/listTrading/actions'
import { getListTradingWatchlistId } from '../../store/listTrading/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import {
  getWebSettingsLoaded,
  showLTSetting
} from '../../store/webSettings/selectors'

import styles from './styles.module.scss'

const TradingListLayout = () => {
  const dispatch = useAppDispatch()
  const selectedWatchlistId = useAppSelector(getListTradingWatchlistId)
  const settingsLoaded = useAppSelector(getWebSettingsLoaded)
  const showLT = useAppSelector(showLTSetting)

  useEffect(() => {
    return () => {
      dispatch(setListTradingWatchlistId(undefined))
    }
  }, [])

  if (!settingsLoaded) {
    return <Loading />
  }
  if (!showLT || selectedWatchlistId === 'invalidWatchlist') {
    return <Navigate replace to="/" />
  }
  return (
    <div className={styles.listTradingUi}>
      {/* common ui elements here */}
      <Outlet />
    </div>
  )
}

export default TradingListLayout

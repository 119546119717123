import React, { useCallback } from 'react'
import RadioButtonGroup from '../../../components/RadioButtonGroup/RadioButtonGroup'
import toggleStyles from '../../../components/RadioButtonGroup/ToggleStyles.module.scss'

interface Props {
  securityId: number
  isBid: boolean | undefined | null
  onChange?: (newVal: boolean) => void
}

export const BuySellSwitch = ({ securityId, isBid, onChange }: Props) => {
  const switchId = `list-trading-order-${securityId}-isBid-toggle`
  const toggleOptions = [
    { label: 'Buy', value: true },
    { label: 'Sell', value: false }
  ]

  const onSwitchToggle = useCallback(
    (value: boolean) => {
      if (!onChange) return
      onChange(value)
    },
    [onChange]
  )

  return (
    <RadioButtonGroup
      options={toggleOptions}
      className={toggleStyles.toggle}
      onSelect={onSwitchToggle}
      selectedOption={isBid}
      testPrefix={switchId}
    />
  )
}

import { useEffect, useRef } from 'react'

const preventRightClickMenu = (e: PointerEvent) => {
  e.stopPropagation()
}
const handleContextMenu = (el: HTMLInputElement | null) => {
  if (el) {
    el.addEventListener('contextmenu', preventRightClickMenu)
    return () => el.removeEventListener('contextmenu', preventRightClickMenu)
  }
}

export const useManageGridCellFocus = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputRef.current?.focus()
    return handleContextMenu(inputRef.current)
  }, [inputRef.current])
  return inputRef
}

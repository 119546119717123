import { Id } from 'react-toastify'
import { Action } from 'redux'
import { Toast } from './types'
import { OrdersUpdatedAction } from '../order/actions'

export interface AddToastAction extends Action {
  type: 'toasts.AddToast'
  payload: Toast
}

export const addToast = (toast: Toast): AddToastAction => ({
  type: 'toasts.AddToast',
  payload: toast
})

export interface RemoveToastAction extends Action {
  type: 'toasts.RemoveToast'
  payload: Id
}

export const removeToast = (toastId: Id): RemoveToastAction => ({
  type: 'toasts.RemoveToast',
  payload: toastId
})

export interface ClearToastsAction extends Action {
  type: 'toasts.ClearToasts'
}

export const clearToasts = (): ClearToastsAction => ({
  type: 'toasts.ClearToasts'
})

export type ToastAction =
  | AddToastAction
  | RemoveToastAction
  | ClearToastsAction
  | OrdersUpdatedAction

import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import styles from './styles.module.css'

interface Props {
  className?: string
  onChange?: (value: boolean) => void
  defaultIcon: React.ComponentType
  checkedIcon?: React.ComponentType
  disabledIcon?: React.ComponentType
  disabledCheckedIcon?: React.ComponentType
  indeterminateIcon?: React.ComponentType
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
  testId?: string
  title?: string
}

const IconCheckbox = ({
  className,
  onChange,
  defaultIcon,
  checkedIcon,
  indeterminateIcon,
  disabledIcon,
  disabledCheckedIcon,
  checked,
  disabled,
  indeterminate,
  testId,
  title
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!indeterminate
    }
  }, [inputRef.current, indeterminate])

  const onInputChange = useCallback(
    ({
      currentTarget: { checked: lintProofChecked }
    }: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(lintProofChecked)
    },
    [onChange]
  )

  const Icon = useMemo(() => {
    if (checked) {
      return (disabled ? disabledCheckedIcon : checkedIcon) || defaultIcon
    }
    if (indeterminate && indeterminateIcon) {
      return indeterminateIcon
    }
    if (disabled && disabledIcon) {
      return disabledIcon
    }
    return defaultIcon
  }, [
    checked,
    disabled,
    indeterminate,
    checkedIcon,
    defaultIcon,
    disabledIcon,
    disabledCheckedIcon,
    indeterminateIcon
  ])

  const classes = `${className} ${styles.default}`
  return (
    <label className={classes} title={title ?? ''}>
      <input
        type="checkbox"
        onChange={onInputChange}
        data-testid={testId}
        checked={checked}
        disabled={disabled}
        ref={inputRef}
      />
      <Icon />
    </label>
  )
}

export default IconCheckbox

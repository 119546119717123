import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { getOrderTypeFromResponse } from '../../../store/order/helpers'
import { useAppDispatch, useAppSelector } from '../../../store/types'
import {
  deselectSecurityOrdersToCancel,
  selectSecurityOrdersToCancel,
  toggleCancelSubOrderSelection
} from '../../../store/listTrading/actions'
import { getOrdersSelectedForCancel } from '../../../store/listTrading/selectors'
import { ListTradingSecurity } from '../../../store/listTrading/types'
import {
  getHedgeOrder,
  getListTradeError,
  getListTradeOrders,
  getListTradeOrdersForSecurity
} from '../../../store/order/selectors'
import { Order } from '../../../store/order/types'
import { getDisplayStatus, isPending } from '../helpers'

type StatusName =
  | 'Traded'
  | 'Pending'
  | 'Error'
  | 'Crossed'
  | 'Canceled'
  | 'Rejected'
type StatusTuple = [StatusName, number]

export const useListTradingAggressOrders = (
  securityId?: ListTradingSecurity['id'],
  isBid?: boolean | null,
  watchlistId?: number
) => {
  const dispatch = useAppDispatch()
  const getAggressOrders = useAppSelector(getListTradeOrders)
  const getTradedOrders = useAppSelector(getListTradeOrdersForSecurity)
  const getSelectedOrders = useAppSelector(getOrdersSelectedForCancel)
  const getTreasuryHedge = useAppSelector(getHedgeOrder)
  const getError = useAppSelector(getListTradeError)

  const oppositeType = isBid ? 'buy' : 'sell'

  // not sure why it thinks this was already declared
  // @ts-ignore
  const { orders, hedgeOrders } = useMemo<{
    orders: Order[]
    hedgeOrders: Order[]
  }>(() => {
    if (!securityId || !watchlistId) {
      return {
        orders: [] as Order[],
        hedgeOrders: [] as Order[]
      }
    }
    const aggressOrders = getAggressOrders(
      securityId,
      oppositeType,
      watchlistId
    )
    const currentOrders = aggressOrders.filter((order) => {
      const orderTime = order.tradeTime || order.submitTime
      return dayjs(orderTime).isSame(dayjs(), 'day')
    })
    const hos = currentOrders
      .map((order) => getTreasuryHedge(order.id))
      .filter((order) => !!order)
    return { orders: currentOrders, hedgeOrders: hos }
  }, [getAggressOrders, securityId, watchlistId, oppositeType])

  const toggleOrderCancel = useCallback(
    (orderId: string) => {
      if (securityId) {
        dispatch(toggleCancelSubOrderSelection(securityId, orderId))
      }
    },
    [dispatch]
  )

  const selectOrDeselectAllSecurityOrders = useCallback(
    (select: boolean) => {
      if (!securityId || !watchlistId) return
      if (select) {
        const selectOrders = getAggressOrders(
          securityId,
          oppositeType,
          watchlistId
        )
          .filter(isPending)
          .map((order) => order.id)
        dispatch(selectSecurityOrdersToCancel(securityId, selectOrders))
      } else {
        dispatch(deselectSecurityOrdersToCancel(securityId))
      }
    },
    [dispatch, getAggressOrders, oppositeType]
  )

  const orderSummary = useMemo<StatusTuple[]>(() => {
    if (!securityId || !watchlistId) return []
    const aggressOrders =
      getAggressOrders(securityId, oppositeType, watchlistId) ?? []
    const initialOrders = getTradedOrders(securityId) ?? []
    const initialIds = initialOrders.map((o) => o.id)

    const thisType = isBid ? 'sell' : 'buy'

    const statusesInOrder = [
      'Traded',
      'Pending',
      'Cancelled',
      'Rejected',
      'Crossed',
      'Error'
    ] as const

    const incrementTuple = (
      tuples: StatusTuple[],
      tupleName: StatusName
    ): StatusTuple[] => {
      const tupleIndex = statusesInOrder.findIndex(
        (status) => status === tupleName
      )
      if (tupleIndex === -1) return tuples
      return tuples.map((item, i) =>
        i === tupleIndex ? [item[0], item[1] + 1] : item
      )
    }

    const passiveStatuses = initialOrders
      .map((o) =>
        // @ts-ignore
        typeof o.type === 'number'
          ? { ...o, type: getOrderTypeFromResponse(o.type) }
          : o
      )
      .filter((o) => o.type === thisType)
      .reduce(
        (statuses, order) => {
          if (getError(order.id)) {
            return incrementTuple(statuses, 'Error')
          }

          const aggressOrder = aggressOrders.find(
            (o) => o.initialOrder?.id === order.id
          )
          if (!aggressOrder) return statuses
          return incrementTuple(
            statuses,
            getDisplayStatus(aggressOrder.status) as StatusName
          )
        },
        [
          ['Traded', 0],
          ['Pending', 0],
          ['Cancelled', 0],
          ['Rejected', 0],
          ['Crossed', hedgeOrders.length],
          ['Error', 0]
        ] as StatusTuple[]
      )

    const otherAggressOrders = aggressOrders.filter(
      (agg) =>
        !initialIds.includes(agg.initialOrder?.id ?? 'not an id') &&
        dayjs().isSame(agg.tradeTime ?? agg.submitTime, 'day')
    )

    return otherAggressOrders
      .reduce((statuses, aggressOrder) => {
        return incrementTuple(
          statuses,
          getDisplayStatus(aggressOrder.status) as StatusName
        )
      }, passiveStatuses)
      .filter((status) => status[1])
  }, [
    securityId,
    oppositeType,
    getAggressOrders,
    getListTradeError,
    getTradedOrders,
    hedgeOrders
  ])

  if (!watchlistId || !securityId) {
    return {
      hasPendingOrders: false,
      orders: [] as Order[],
      hedgeOrders: [] as Order[],
      selectedOrderIds: [] as string[],
      toggleOrderCancel: (_orderId: string) => {
        /* intentionally left blank */
      },
      selectOrDeselectAllSecurityOrders: () => {
        /* intentionally left blank */
      },
      orderSummary
    }
  }

  const selectedOrderIds = getSelectedOrders(securityId)
  const hasPendingOrders = orders.some(isPending)

  return {
    hasPendingOrders,
    orders,
    hedgeOrders,
    orderSummary,
    selectedOrderIds,
    toggleOrderCancel,
    selectOrDeselectAllSecurityOrders
  }
}

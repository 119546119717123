const secondsDiff = (d1: Date, d2: Date) =>
  Math.max((d1.valueOf() - d2.valueOf()) / 1000, 0)

export const getTotalSecondsBeforeExpiration = (
  expiration: Date | undefined,
  submitTime: Date | undefined,
  serverTimeDelayMs: number | undefined = 0
) => {
  const adjustedSubmitTime =
    submitTime && new Date(submitTime.valueOf() - serverTimeDelayMs)
  const adjustedExpiration =
    expiration && new Date(expiration.valueOf() - serverTimeDelayMs)
  const totalSeconds =
    adjustedExpiration && adjustedSubmitTime
      ? secondsDiff(adjustedExpiration, adjustedSubmitTime)
      : 0
  const secondsLeft = Math.max(
    adjustedExpiration ? secondsDiff(adjustedExpiration, new Date()) : 0,
    0
  )
  return { totalSeconds, secondsLeft }
}

import { CustomHeaderProps } from '@ag-grid-community/react'
import {
  faTrashCanCheck,
  faTrashCanList
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import IconCheckbox from '../../../components/IconCheckbox/IconCheckbox'
import { SecurityStaticData } from '../../../store/securities/reducer'
import { TradingListContext } from '../Grid'

const defaultIcon = () => <FontAwesomeIcon icon={faTrashCanList} />
const checkedIcon = () => <FontAwesomeIcon icon={faTrashCanCheck} />
const HideRowHeaderCell = ({
  context: { hideRowHeaderState, toggleHideRowsHeaderCheck }
}: CustomHeaderProps<SecurityStaticData, TradingListContext>) => {
  return (
    <IconCheckbox
      defaultIcon={defaultIcon}
      checkedIcon={checkedIcon}
      checked={hideRowHeaderState === 'checked'}
      indeterminate={hideRowHeaderState === 'indeterminate'}
      onChange={toggleHideRowsHeaderCheck}
      testId="lt-hide-row-column-header-check"
    />
  )
}

export default HideRowHeaderCell

import {
  faCircleMinus,
  faXmark
} from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import IconCheckbox from './IconCheckbox'

interface Props {
  onChange: (value: boolean) => void
  className?: string
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
  testId?: string
  title?: string
}

const defaultIcon = () => <FontAwesomeIcon icon={faXmark} />
const indeterminateIcon = () => <FontAwesomeIcon icon={faCircleMinus} />
const CancelCheck = (props: Props) => {
  return (
    <IconCheckbox
      defaultIcon={defaultIcon}
      indeterminateIcon={indeterminateIcon}
      {...props}
    />
  )
}

export default CancelCheck

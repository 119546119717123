import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@awesome.me/kit-5de77b2c02/icons/classic/solid'
import cx from 'classnames'
import React, { FC, useCallback, useRef } from 'react'
import { noop } from 'rxjs'
import { useDropDownMenu } from '../../helpers/hooks'
import { getAllOrNoneStatus } from '../../store/helpers'
import Checkbox from '../Checkbox'
import DropdownList from '../DropdownList/DropdownList'
import styles from './GenericDropdownMenu.module.scss'

interface ListItemProps {
  id: string
  label: string
  selected: boolean
  toggleSelected: () => void
}

export const ListItem = ({
  id,
  label,
  selected,
  toggleSelected
}: ListItemProps) => {
  return (
    <div className={styles.listItem}>
      <Checkbox
        locator={`column-${id}`}
        checked={selected}
        onChange={toggleSelected}
      >
        {label}
      </Checkbox>
    </div>
  )
}

interface SelectAllProps {
  status: 'all' | 'none' | 'some'
  changeStatus: () => void
}

export const SelectAll: FC<SelectAllProps> = ({ status, changeStatus }) => {
  return (
    <div className={styles.listItem}>
      <Checkbox
        locator={`column-all`}
        checked={status === 'all'}
        indeterminate={status === 'some'}
        onChange={changeStatus}
      >
        Select all
      </Checkbox>
    </div>
  )
}

export interface Props {
  placeholder: string
  selectedItems: string[]
  setSelectedItems(items: string[]): void
  options: string[]
  selectId: string
  className: string
  openRight: boolean
}

const GenericDropdownMenuMulti: FC<Props> = ({
  placeholder,
  selectedItems,
  setSelectedItems,
  options,
  selectId,
  className,
  openRight = false
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { displayMenu, setDisplayMenu } = useDropDownMenu(ref)

  const showOrHideDropdown = useCallback(() => {
    setDisplayMenu((value) => !value)
    setSelectedItems(selectedItems)
  }, [selectedItems, setDisplayMenu])

  const isItemSelected = (colId: string) => selectedItems.includes(colId)

  const toggleSelected = useCallback(
    (colId: string) => () => {
      setSelectedItems(
        isItemSelected(colId)
          ? selectedItems.filter((c) => c !== colId)
          : [...selectedItems, colId]
      )
    },
    [selectedItems]
  )

  const allSelectedStatus = getAllOrNoneStatus(options, (column) =>
    isItemSelected(column)
  )

  const changeSelectAllStatus = useCallback(() => {
    switch (allSelectedStatus) {
      case 'none':
      case 'some':
        setSelectedItems(options)
        break
      case 'all':
        setSelectedItems([])
        break
    }
  }, [allSelectedStatus])

  return (
    <div ref={ref} className={cx(styles.dropdown, className)}>
      <div onClick={showOrHideDropdown}>
        <label>{placeholder}</label>
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
      {displayMenu && (
        <>
          <div className={openRight ? styles.menuRight : styles.menu}>
            <div className={styles.selectAll}>
              <DropdownList
                onElementSelected={noop} // So the elements look clickable, even if we handle the click in the onChange event of the checkbox.
                elements={[
                  {
                    id: { selectId },
                    label: (
                      <SelectAll
                        status={allSelectedStatus}
                        changeStatus={changeSelectAllStatus}
                      />
                    )
                  }
                ]}
              />
            </div>
            <DropdownList
              onElementSelected={noop} // So the elements look clickable, even if we handle the click in the onChange event of the checkbox.
              elements={options.map((name) => ({
                id: name,
                label: (
                  <ListItem
                    id={name}
                    label={name}
                    selected={isItemSelected(name)}
                    toggleSelected={toggleSelected(name)}
                  />
                )
              }))}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default GenericDropdownMenuMulti

import React, { memo, useEffect } from 'react'
import { useLocation } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import { removeToast } from '../../store/toasts/actions'
import { getToasts } from '../../store/toasts/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import styles from './Toasts.module.scss'

const Toasts = () => {
  const toasts = useAppSelector(getToasts)
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    if (toasts) {
      toasts.forEach((item) => {
        const { message, options, additionalFunc } = item
        options.onOpen = () => {
          dispatch(removeToast(options.toastId!))
          additionalFunc && dispatch(additionalFunc)
        }

        toast[options.type](message, options)
      })
    }
  }, [toasts, dispatch])

  useEffect(() => {
    toast.dismiss()
  }, [location])

  return (
    <ToastContainer
      toastClassName={styles.toasts}
      position="top-center"
      theme="colored"
      // limit={8} // causes toasts after the limit to repeat
    />
  )
}

export default memo(Toasts)

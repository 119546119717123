import React, { PropsWithChildren } from 'react'
import styles from './index.module.scss'

interface Props {
  onClick: () => void
  active?: boolean
}

const FilterPill = ({
  active,
  onClick,
  children
}: PropsWithChildren<Props>) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.pill} ${active ? styles.active : ''}`}
    >
      {children}
    </div>
  )
}

export default FilterPill

import { createSelector } from 'reselect'
import { namespace } from '.'
import { State } from './reducer'

export const getState = (state: { [namespace]: State }) => state[namespace]

export const isPending = createSelector([getState], (state) => state.pending)
export const hasError = createSelector([getState], (state) => state.error)
export const isConnected = createSelector(
  [getState],
  (state) => state.connected
)

export const getServerTimeDelayMs = createSelector(
  [getState],
  (state) => state.serverTimeDelayMs
)

export const getMustReload = createSelector(
  [getState],
  (state) => state.mustReload
)

import { ToastAction } from './actions'
import { Toast } from './types'

export interface State {
  toasts: Toast[]
}

export const initialState: State = {
  toasts: []
}

export default (state = initialState, action: ToastAction): State => {
  switch (action.type) {
    case 'toasts.AddToast':
      return {
        ...state,
        toasts: [...state.toasts, action.payload]
      }

    case 'toasts.RemoveToast':
      return {
        ...state,
        toasts: state.toasts.filter(
          (toast) => toast.options.toastId !== action.payload
        )
      }

    case 'toasts.ClearToasts':
      return {
        ...state,
        toasts: []
      }

    default:
      return state
  }
}
